import React from 'react';
import './App.css';
import { Router } from 'react-router-dom';
import history from './Routes/History';
import Routes from './Routes/Route';

function App() {
    return (
        <Router history={history}>
            <Routes />
        </Router>
    );
}

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import './BubbleSpinLoader.css';

const BubbleSpinLoader = ({ isLoading = false }) => {
    return (
        <>
            {isLoading && (
                <div className="loader-wrapper">
                    <div className="bubble-spin"></div>
                    <h3 className="text-align-center">Please wait , while we are verifying your identiy.</h3>
                </div>
            )}
        </>
    );
};

BubbleSpinLoader.propTypes = {
    color: PropTypes.string,
    duration: PropTypes.number,
    size: PropTypes.number,
    isLoading: PropTypes.bool,
};

BubbleSpinLoader.defaultProps = {
    color: '#C83A2A',
    duration: 1.3,
    size: 20,
};

export default BubbleSpinLoader;

/* eslint-disable react/prop-types */
// import axios from 'axios';
import { OpenVidu, Filter } from 'openvidu-browser';
import React, { Component } from 'react';
import './WaitingRoom.css';
import UserVideoComponent from '../OpenVidu/UserVideoComponent';
import BubbleSpinLoader from '../Common/BubbleSpinLoader';
import JoinCallComponent from './JoinCallComponent';
import { v1 as uuidv1 } from 'uuid';
import { getAuthCode, getToken } from '../../Business/Auth';
import Error from '../Common/ErrorPage';

class WaitingRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mySessionId: 'SessionA',
            myUserName: '',
            session: undefined,
            mainStreamManager: undefined,
            publisher: undefined,
            subscribers: undefined,
            isLoading: false,
            isLoadingLocation: true,
            currentLocation: '',
            authCode: '',
            isLocationRequestGranted: false,
            errorMessage: '',
            isError: false,
            interviewType: 2,
            defaultOrgCode: '',
        };

        this.joinSession = this.joinSession.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
        this.isFrontCamera = true;
        this.OV = new OpenVidu();
        this.sessionSalt = null;
        this.loadingText = 'Getting response....';
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.onbeforeunload);
        this.setPublisher();
        this.getInterviewType();
        // this.getUserLocation();
        this.getAccessCode();
        this.getDefaultOrgCode();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
        this.setState({
            subscribers: undefined,
        });
    }

    getDefaultOrgCode = () => {
        fetch('/si-applicant-web/orgconfig.json')
            .then((response) => response.json())
            .then((data) => {
                console.log('Got data from org config file.');
                console.log(data);
                this.setState({
                    defaultOrgCode: data['defaultOrgCode'],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getUserLocation = () => {
        var options = { timeout: 5000 };
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log('location', position.coords);
                this.setState({
                    isLocationRequestGranted: true,
                    isLoadingLocation: false,
                    currentLocation: position.coords,
                });
            },
            (error) => {
                console.log('getUserLocation error', error);
                this.loadingText = 'Location permission denied. You have to enable location to continue.';
                if (this.state.interviewType == '2') {
                    this.setState({
                        isLocationRequestGranted: true,
                    });
                } else {
                    this.setState({
                        isLocationRequestGranted: false,
                    });
                }
            },
            options,
        );
    };

    getSessionSalt() {
        if (this.sessionSalt == null || this.sessionSalt == undefined) {
            this.sessionSalt = uuidv1().replace(/-/g, '');
        }
        return this.sessionSalt;
    }

    getAccessCode = () => {
        let params = new URLSearchParams(this.props.location.search);
        return params.get('accessCode');
    };

    getOrgCode = () => {
        let params = new URLSearchParams(this.props.location.search);
        let orgCode = params.get('orgCode');
        if (orgCode && orgCode != '') return orgCode;
        else {
            console.log('Using default orgCode: ' + this.state.defaultOrgCode);
            return this.state.defaultOrgCode;
        }
    };

    getInterviewType = async () => {
        let params = await new URLSearchParams(this.props.location.search);
        this.setState(
            {
                interviewType: params.get('type'),
            },
            this.getUserLocation(),
        );
    };
    setPublisher = () => {
        let publisher = this.OV.initPublisher(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource: undefined, // The source of video. If undefined default webcam
            publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
            publishVideo: true, // Whether you want to start publishing with your video enabled or not
            resolution: '640x480', // The resolution of your video
            frameRate: 30, // The frame rate of your video
            insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
            mirror: true, // Whether to mirror your local video or not
            filter: new Filter('GammaEmotionFilter', {}),
        });
        console.log('publisher', publisher);
        this.setState({
            publisher: publisher,
        });
    };

    toggleCamera = () => {
        this.OV.getDevices().then((devices) => {
            // Getting only the video devices

            var videoDevices = devices.filter((device) => device.kind === 'videoinput');

            if (videoDevices && videoDevices.length > 1) {
                // Changing isFrontCamera value
                this.isFrontCamera = !this.isFrontCamera;

                // Creating a new publisher with specific videoSource
                // In mobile devices the default and first camera is the front one
                var properties = {
                    videoSource: this.isFrontCamera ? videoDevices[0].deviceId : videoDevices[1].deviceId,
                    publishAudio: true,
                    publishVideo: true,
                    mirror: this.isFrontCamera, // Setting mirror enable if front camera is selected
                };
                if (this.isFrontCamera) {
                    properties.filter = new Filter('GammaEmotionFilter', {});
                }
                var newPublisher = this.OV.initPublisher('html-element-id', properties);

                if (this.state.session) {
                    // Unpublishing the old publisher
                    this.state.session.unpublish(this.state.publisher);

                    // Publishing the new publisher
                    this.state.session.publish(newPublisher);
                }

                this.setState({
                    publisher: newPublisher,
                });
            }
        });
    };

    onbeforeunload(event) {
        this.leaveSession();
    }

    handleChangeSessionId(e) {
        this.setState({
            mySessionId: e.target.value,
        });
    }

    handleChangeUserName(e) {
        this.setState({
            myUserName: e.target.value,
        });
    }

    handleMainVideoStream(stream) {
        if (this.state.mainStreamManager !== stream) {
            this.setState({
                mainStreamManager: stream,
            });
        }
    }

    deleteSubscriber(streamManager) {
        let subscribers = this.state.subscribers;
        let index = subscribers.indexOf(streamManager, 0);
        if (index > -1) {
            subscribers.splice(index, 1);
            this.setState({
                subscribers: subscribers,
            });
        }
    }

    async joinSession() {
        var accessCode = this.getAccessCode();
        var orgCode = this.getOrgCode();
        this.loadingText = 'Getting response....';
        this.setState({
            isLoading: true,
        });
        let sessionSalt = this.getSessionSalt();
        try {
            let result = await getAuthCode(
                process.env.REACT_APP_USER_NAME,
                process.env.REACT_APP_PASSWORD,
                sessionSalt,
                orgCode,
            );

            if (result) {
                this.setState({
                    authCode: result['x-slp-auth-code'],
                });

                let authCode = result['x-slp-auth-code'];
                let mySession = this.OV.initSession();

                mySession.on('streamCreated', (event) => {
                    var subscriber = mySession.subscribe(event.stream, undefined);
                    console.log('streamCreated');
                    // Update the state with the new subscribers
                    this.setState({
                        subscribers: subscriber,
                    });
                });

                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event) => {
                    console.log('streamDestroyed');
                    // Remove the stream from 'subscribers' array
                    // this.deleteSubscriber(event.stream.streamManager);
                    this.setState({
                        subscribers: undefined,
                    });
                });

                // --- 4) Connect to the session with a valid user token ---

                // 'token' parameter should be retrieved and returned by your own backend
                getToken(accessCode, orgCode, sessionSalt, authCode, this.state.currentLocation).then((response) => {
                    console.log('Token Received', response);
                    if (response.code === '000000') {
                        mySession
                            .connect(response.data.token, { clientData: this.state.myUserName })
                            .then(() => {
                                console.log('Conneting the call', response.data.token);
                                // Publish your stream ---

                                mySession.publish(this.state.publisher).then(() => console.log('Video published.'));

                                this.setState({
                                    session: mySession,
                                });
                            })
                            .catch((error) => {
                                this.setState({
                                    isError: true,
                                    errorMessage: error.message,
                                });
                            });
                    } else {
                        this.setState({
                            isError: true,
                            errorMessage: response.data,
                        });
                    }
                });
            }
        } catch (e) {
            console.log(e.message);
            this.setState({
                isLoading: false,
                isError: true,
                errorMessage: e.message,
            });
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    isMyOwnConnection = (connectionId) => {
        let isTrue = this.publisher?.getConnectionId() === connectionId;
        return isTrue;
    };

    // ------------- Emotion risk Gammalab -----------//
    // addFilterEvent = () => {
    //     const publisher = this.state.publisher;
    //     if (publisher) {
    //         publisher.stream.filter.stream = publisher.stream;
    //         publisher.stream.filter.addEventListener('emotionResultCb', (filterEvent) => {
    //             console.log(filterEvent);
    //             // console.log(filterEvent.data);
    //             this.state.session
    //                 .signal({
    //                     data: JSON.stringify(filterEvent.data),
    //                     to: [],
    //                     type: 'emotionResult',
    //                 })
    //                 .then(() => {
    //                     console.log('addFilterEvent() successful');
    //                 })
    //                 .catch((error) => {
    //                     console.error(error);
    //                 });
    //         });
    //     }
    // };

    // enableEmotionFilter = () => {
    //     const publisher = this.state.publisher;
    //     if (publisher) {
    //         publisher.stream.filter.stream = publisher.stream;
    //         publisher.stream.filter
    //             .execMethod('enableFilter', {})
    //             .then(() => {
    //                 console.log('enableEmotionFilter() successful');
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //             });
    //     }
    // };

    // disableEmotionFilter = () => {
    //     const publisher = this.state.publisher;
    //     if (publisher) {
    //         publisher.stream.filter.stream = publisher.stream;
    //         publisher.stream.filter
    //             .execMethod('disableFilter', {})
    //             .then(() => {
    //                 console.log('disableEmotionFilter() successful');
    //             })
    //             .catch((error) => {
    //                 console.error(error);
    //             });
    //     }
    // };

    dismissError = () => {
        this.setState({
            isError: false,
            errorMessage: '',
            session: undefined,
        });
    };

    leaveSession() {
        //Leave the session by calling 'disconnect' method over the Session object ---
        // this.disableEmotionFilter();
        // this.state.publisher.stream.filter.removeEventListener('emotionResultCb');
        const mySession = this.state.session;

        if (mySession) {
            mySession.unpublish(this.state.publisher);
            mySession.disconnect();
        }

        // Empty all properties...
        this.OV = null;
        this.setState({
            session: undefined,
            subscribers: [],
            mySessionId: 'SessionA',
            myUserName: '',
            mainStreamManager: undefined,
        });
    }

    render() {
        const myUserName = this.state.myUserName;
        return (
            <div>
                <div className="container-fluid flex-center">
                    <>
                        {this.OV && this.state.session === undefined ? (
                            <JoinCallComponent
                                myUserName={myUserName}
                                publisher={this.state.publisher}
                                ov={this.OV}
                                toggleCamera={this.toggleCamera}
                                joinSession={this.joinSession}
                                handleChangeUserName={this.handleChangeUserName}
                                isLocationRequestGranted={this.state.isLocationRequestGranted}
                            />
                        ) : null}
                        {this.state.session !== undefined && this.state.isError === false ? (
                            <div id="session" className="container flex-center">
                                {/* <div id="session-header">
                                    <h1 id="session-title">{mySessionId}</h1>
                                </div> */}
                                <div id="video-container" className="row">
                                    {this.state.publisher !== undefined ? (
                                        <div
                                            className={
                                                this.state.publisher !== undefined &&
                                                this.state.subscribers !== undefined
                                                    ? 'col-lg-6'
                                                    : 'col-lg-12'
                                            }
                                            onClick={() => this.handleMainVideoStream(this.state.publisher)}
                                        >
                                            <UserVideoComponent
                                                streamManager={this.state.publisher}
                                                OV={this.OV}
                                                isPublisher={true}
                                                toggleCamera={this.toggleCamera}
                                                isSessionStarted={true}
                                            />
                                        </div>
                                    ) : null}

                                    {this.state.subscribers !== undefined ? (
                                        <div
                                            className="col-lg-6"
                                            onClick={() => this.handleMainVideoStream(this.state.subscribers)}
                                        >
                                            <UserVideoComponent
                                                streamManager={this.state.subscribers}
                                                OV={this.OV}
                                                isPublisher={false}
                                                isSessionStarted={true}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <div className="row">
                                    {this.state.subscribers == undefined && (
                                        <h3 className="text-align-center">Please wait, Interviewer will join soon.</h3>
                                    )}
                                </div>

                                <div className="row leave-button-warpper">
                                    <input
                                        className="btn btn btn-danger e-kyc-button"
                                        type="button"
                                        id="buttonLeaveSession"
                                        onClick={this.leaveSession}
                                        value="Leave session"
                                    />
                                </div>
                            </div>
                        ) : null}
                    </>
                    <Error
                        isError={this.state.isError}
                        dismissError={this.dismissError}
                        errorMessage={this.state.errorMessage}
                    ></Error>
                    <BubbleSpinLoader isLoading={this.state.isLoading}></BubbleSpinLoader>
                </div>
            </div>
        );
    }
}

export default WaitingRoom;

import React from 'react';

const Error = () => {
    return (
        <div className="container flex-center">
            <h3 style={{ textAlign: 'center' }}>Opps Sorry, You are on wrong page</h3>
        </div>
    );
};

export default Error;

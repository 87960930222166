/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Footer.css';
// import Email from '../../Images/email.png';
import Phone from '../../Images/phone.png';
import Web from '../../Images/web.png';

export default function Footer() {
    return (
        <footer>
            <div className="footer-block-1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>For any service related queries, please contact us:</p>
                            <section>
                                <span>
                                    <img src={Phone} />
                                    <a href="tel: 1800 270 7000"> 1800 270 7000</a>
                                </span>
                                {/* <span className="divider hidden-xs">|</span>
                                <br className="visible-xs" />
                                <span>
                                    <img src={Email} />
                                    <a href="#">care.housingfinance@adityabirlacapital.com</a>
                                </span> */}
                                <span className="divider hidden-xs">|</span>
                                <br className="visible-xs" />
                                <span>
                                    <img src={Web} />
                                    <a href="https://www.adityabirlacapital.com/" target="_blank">
                                        adityabirlacapital.com
                                    </a>
                                </span>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="footer-block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                <b>Aditya Birla Housing Finance Limited</b>
                            </p>
                            <p style={{ marginBottom: 8 }}>
                                <b>Regd. Office:</b>Indian Rayon Compound, Veraval - 362 266, Gujarat.
                            </p>
                            <p>
                                <b>Corporate Office:</b> 18th Floor, Tower 1, One World Centre, Jupiter Mill Compound,
                                841, Senapati Bapat Marg, Elphinstone Road, Mumbai 400 013 |<br /> (T){' '}
                                <a href="tel:+912243567100">+91 22 4356 7100</a> | (F){' '}
                                <a href="tel:+912243567266">+91 22 4356 7266</a> | CIN: U65922GJ2009PLC083779.
                            </p>
                            <p style={{ marginBottom: 8 }}>
                                For more information, please visit:{' '}
                                <a href="https://homefinance.adityabirlacapital.com/" target="_blank">
                                    homefinance.adityabirlacapital.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-block-4">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {' '}
                                <strong>Disclaimers:</strong> <br /> ABHFL does not undertake any liability or
                                responsibility for any loss or damage incurred by anyone in reliance on anything set out
                                in the details, content and information provided on the site and/or due to
                                acts/omissions of
                                <br /> the third party.{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-block-4 top-border">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                Loans originated, booked and serviced by Aditya Birla Housing Finance Limited are
                                subject to credit appraisal in sole discretion of Aditya Birla Housing Finance Limited{' '}
                                <br /> and terms and conditions as agreed under individual loan agreement.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
    );
}

{
    /* <footer className="container-fluid footer-custom">
<div className="footer-content ">FOOTER</div>
<div className="footer-copyright footer-copyright-custom">© 2020</div>
</footer> */
}

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './SubHeader.css';

export default function SubHeader(props) {
    console.log('SubHeader props', props);
    // const [name, setName] = useState('');
    const [orgDetails, setOrgDetails] = useState({
        name: '',
        fullName: '',
        barThemeColor: '',
    });
    const [fullName, setFullName] = useState('');
    const search = useLocation().search;
    const accessCode = new URLSearchParams(search).get('accessCode');
    const orgCode = new URLSearchParams(search).get('orgCode');
    console.log(accessCode, orgCode);

    useEffect(() => {
        lobNames();
    }, [orgCode]);

    // const getlob = () => {
    //     setLob(params.get('orgCode'));
    // };
    const lobNames = () => {
        console.log('Loading lob names.');
        fetch('/si-applicant-web/orgconfig.json')
            .then((response) => response.json())
            .then((data) => {
                console.log('Got data from org config file.');
                console.log(data);
                const org = data['orgs'][orgCode];
                setOrgDetails(org);
                if (org) {
                    // setName(org['name']);
                    setFullName(org['fullName']);
                } else {
                    // setName('ADITYA BIRLA');
                    setFullName('Aditya Birla Group');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <section className="sub-header">
            <div className="container">
                <div className="">
                    <div className="col-sm-12" style={{ padding: 0 }}>
                        {/*<div className="sub-header-1">{name}</div>*/}
                        <div
                            className="sub-header-2"
                            style={{ backgroundColor: getSubHeader2BackgroundColor(orgDetails?.barThemeColor) }}
                        >
                            {fullName}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const getSubHeader2BackgroundColor = (theme) => {
    switch (theme) {
        case 'FINANCING TAN':
            return 'rgb(196,161,102)';
        case 'PROTECTING PEACH':
            return 'rgb(214,141,135)';
        default:
            return 'rgb(196,161,102)';
    }
};

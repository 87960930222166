import { JSEncrypt } from 'jsencrypt';
import CryptoJS from 'crypto-js';

class EncryptUtils {
    constructor() {
        this.rsaPublicKey = process.env.REACT_APP_RSA_PUBLIC_KEY;
    }

    generandomhexstr = (length) => {
        let text = '';
        let possible = '123456789abcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    };

    AesEncrypt = (key, content) => {
        var skey = CryptoJS.enc.Utf8.parse(key);
        var siv = CryptoJS.enc.Utf8.parse(key.substring(0, 16));
        const encrypted = CryptoJS.AES.encrypt(content, skey, {
            iv: siv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    };

    RsaEncrypt = (key, content) => {
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(key);
        return encrypt.encrypt(content);
    };
}
let encrypt = new EncryptUtils();
export default encrypt;

import React from 'react';
import { Switch, HashRouter as Router, Route } from 'react-router-dom';
import Authenticate from '../Component/Authenticate/Authenticate';
import WaitingRoom from '../Component/WaitingRoom/WaitingRoom';
import Error from '../Component/Error/Error';
import Navbar from '../Component/Navbar/Navbar';
import Footer from '../Component/Footer/Footer';
import SubHeader from '../Component/SubHeader/SubHeader';

function Routes() {
    return (
        <Router>
            <Navbar />
            <SubHeader />
            <Switch>
                <Route path="/waitingroom" strict component={WaitingRoom} />
                <Route path="/" exact component={Authenticate} />
                <Route component={Error} />
            </Switch>
            <Footer />
        </Router>
    );
}

export default Routes;

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './Authenticate.css';
import BubbleSpinLoader from '../Common/BubbleSpinLoader';
import Error from '../Common/ErrorPage';
import { v1 as uuidv1 } from 'uuid';
import { getAuthCode, getUserData } from '../../Business/Auth';
import Step1 from '../../Images/step1.jpg';
import Step2 from '../../Images/step2.jpg';
import Icon1 from '../../Images/icon1.png';
import Icon2 from '../../Images/icon2.png';
import Icon4 from '../../Images/icon4.png';
import Icon5 from '../../Images/icon5.png';
import Icon6 from '../../Images/icon6.png';
import Icon7 from '../../Images/icon7.png';
import Icon8 from '../../Images/icon8.png';
import PassportIcon from '../../Images/passport_icon.png';
import VoteridIcon from '../../Images/voter_id_icon.png';
import RentIcon from '../../Images/rent_icon.png';

export class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errorMessage: 'sorry efkyc failed',
            requestData:
                '<AadharRequest><VendorID>1</VendorID><Password>iC2pOBYQi6w5hK38DWDyPw==</Password><TxnId>145248</TxnId><DateTime>YYYY-MM-DDThh:mm:ss</DateTime><Redirecturl></Redirecturl><Showresponse>0</Showresponse></AadharRequest>',
            alfafinSoftUrl: 'https://indofinnet.co.in/KYCQuickEnroll/XMLUsingOtp',
            isButtonDisabled: false,
            isError: false,
            defaultOrgCode: '',
        };
        this.formRef = React.createRef();
        this.sessionSalt = null;
    }

    componentDidMount() {
        this.getDefaultOrgCode();
    }

    getDefaultOrgCode = () => {
        fetch('/si-applicant-web/orgconfig.json')
            .then((response) => response.json())
            .then((data) => {
                console.log('Got data from org config file.');
                console.log(data);
                this.setState({
                    defaultOrgCode: data['defaultOrgCode'],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    getSessionSalt() {
        if (this.sessionSalt == null || this.sessionSalt == undefined) {
            this.sessionSalt = uuidv1().replace(/-/g, '');
        }
        return this.sessionSalt;
    }

    getAccessCode = () => {
        let params = new URLSearchParams(this.props.location.search);
        return params.get('accessCode');
    };

    getOrgCode = () => {
        let params = new URLSearchParams(this.props.location.search);
        let orgCode = params.get('orgCode');
        if (orgCode && orgCode != '') return orgCode;
        else {
            console.log('Using default orgCode: ' + this.state.defaultOrgCode);
            return this.state.defaultOrgCode;
        }
    };

    onCompleteKycButtonClick = async (e) => {
        var accessCode = this.getAccessCode();
        var orgCode = this.getOrgCode();
        this.setState({
            isLoading: true,
            isButtonDisabled: true,
        });
        let sessionSalt = this.getSessionSalt();
        try {
            let authResponse = await getAuthCode(
                process.env.REACT_APP_USER_NAME,
                process.env.REACT_APP_PASSWORD,
                sessionSalt,
                orgCode,
            );
            let authCode = authResponse['x-slp-auth-code'];
            let response = await getUserData(accessCode, orgCode, sessionSalt, authCode);
            console.log(response);
            if (response != null && response != undefined) {
                if (response.code === '000000') {
                    let requestData = response.data.value;
                    this.setState(
                        { requestData: requestData, alfafinSoftUrl: response.data.submitUrl },
                        this.navigateToUrl,
                    );
                } else {
                    let message = response.message;
                    this.setState({
                        isError: true,
                        errorMessage: message,
                    });
                }
            }
        } catch (e) {
            this.setState({
                isError: true,
                errorMessage: e.message,
            });
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    dismissError = () => {
        this.setState({
            isError: false,
            isButtonDisabled: false,
            errorMessage: '',
        });
    };

    navigateToUrl = () => {
        this.formRef.current.submit();
    };

    render() {
        return (
            <div>
                <section className="step_heading text-center">
                    <h2>Two simple steps can take you a major step closer to your dream home!</h2>
                    <span>Complete your video KYC in two simple steps</span>
                </section>
                <section className="step1-wrapper">
                    <div className="container">
                        <div className="col-lg-10 step-box col-lg-offset-1">
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <div className="step_icon">
                                        <p>
                                            Step 1: <br />
                                            Aadhaar Verification
                                        </p>
                                        <img src={Step1} alt="" />
                                    </div>
                                </div>

                                <div className="col-md-9 description" style={{ overflow: 'hidden' }}>
                                    <p className="desc_title">
                                        Verify your Aadhaar before starting the video call with the executive. What you
                                        need:
                                    </p>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4 addhar-verification-img">
                                                    <img src={Icon1} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-8 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        Your <br className=" visible-xs visible-sm" /> Your Aadhaar card
                                                        number
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-4 addhar-verification-img">
                                                    <img src={Icon2} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-8 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        Mobile number <br className=" visible-xs visible-sm " /> linked
                                                        to your <br className="visible-xs visible-sm" /> Aadhaar
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="video_cta">
                                        <a href="https://youtu.be/O68vsKAIF9k" target="_blank">
                                            Watch this video
                                        </a>
                                        to know how to complete Aadhaar verification in a few clicks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="step1-wrapper" style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="container">
                        <div
                            className="col-lg-10 step-box col-lg-offset-1"
                            style={{ overflow: 'hidden', paddingRight: 10, paddingLeft: 10 }}
                        >
                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <div className="step_icon">
                                        <p>
                                            Step 2: <br /> Video Call <br className="hidden-xs hidden-sm" /> with
                                            Executive
                                        </p>
                                        <img src={Step2} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-9 description">
                                    <p className="desc_title note">
                                        <u>Things to note:</u>
                                    </p>
                                    <div className="row border">
                                        <div className="col-xs-12 border">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-3 auto" style={{ width: 100 }}>
                                                    <img src={Icon8} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-9 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        If address on application is different from Aadhaar address, any
                                                        one of the below is needed:
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row documents">
                                                <div
                                                    className="col-sm-2 col-md-4 col-lg-3 border"
                                                    style={{ width: 100 }}
                                                ></div>
                                                <div
                                                    className="col-sm-10 col-md-8 col-lg-9 documents_box desc"
                                                    style={{ height: 60, position: 'relative', marginTop: 20 }}
                                                >
                                                    <div className="row i-card-holder">
                                                        <div className="col-xs-6 col-sm-3 box_sm p0">
                                                            <div className="col-xs-12 col-sm-12 p0 ">
                                                                <img
                                                                    src={PassportIcon}
                                                                    style={{ display: 'inline' }}
                                                                    alt=""
                                                                />
                                                                <p style={{ display: 'inline', marginLeft: 6 }}>
                                                                    Passport
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="col-xs-6 col-sm-3 box_sm p0">
                                                            <div className="col-xs-12 col-sm-12 p0">
                                                                <img
                                                                    src={VoteridIcon}
                                                                    style={{ margin: 'auto' }}
                                                                    alt=""
                                                                />
                                                                <p style={{ display: 'inline', marginLeft: 6 }}>
                                                                    Voter ID
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="clearfix visible-xs"></div>
                                                        <div className="col-xs-12 col-sm-4 box_sm p0">
                                                            <div className="col-xs-12 col-sm-12 p0 i-card-holder">
                                                                <img src={RentIcon} style={{ margin: 'auto' }} alt="" />
                                                                <p style={{ display: 'inline', marginLeft: 6 }}>
                                                                    Driver's License
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix row border">
                                        <div className="col-lg-6 border note-instruction">
                                            <div className="row box" style={{ marginTop: 0 }}>
                                                <div className="col-md-4 col-lg-4 auto" style={{ width: 90 }}>
                                                    <img src={Icon4} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-8 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        Start Video KYC session at your scheduled time only
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 border note-instruction">
                                            <div className="row box" style={{ marginTop: 0 }}>
                                                <div className="col-md-4 col-lg-4 auto" style={{ width: 90 }}>
                                                    <img src={Icon5} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-8 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        You will see the “Start” button only after completing your
                                                        Aadhaar verification
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 note-instruction">
                                            <div className="row box">
                                                <div
                                                    className="col-md-4 col-lg-4 auto"
                                                    style={{ width: 90, textAlign: 'center' }}
                                                >
                                                    <img
                                                        src={Icon6}
                                                        className="img-responsive"
                                                        style={{ margin: 'auto' }}
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-8 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        Follow the instructions about camera, location access and
                                                        microphone as they appear on the screen
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 note-instruction">
                                            <div className="row box">
                                                <div className="col-md-4 col-lg-4 auto" style={{ width: 90 }}>
                                                    <img src={Icon7} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-8 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        Have a smartphone with a stable internet connection handy
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <div className="col-xs-12">
                                            <div className="row box">
                                                <div className="col-md-4 col-lg-3 auto" style={{ width: 100 }}>
                                                    <img src={Icon8} className="img-responsive" alt="" />
                                                </div>
                                                <div
                                                    className="col-md-8 col-lg-9 desc"
                                                    style={{ height: 60, position: 'relative' }}
                                                >
                                                    <p className="v-center">
                                                        Give your consent by checking the box below and Click “Agree” in
                                                        the following pop up box to give your consent for Video KYC{' '}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn_cta text-center">
                        <span>To complete step 1</span>
                        <button
                            className="varify_btn"
                            data-toggle="modal"
                            data-target=".myModal"
                            style={{ marginTop: 20 }}
                            disabled={this.state.isButtonDisabled}
                        >
                            VERIFY YOUR AADHAAR NOW!{' '}
                        </button>
                    </div>
                </section>

                <div className="modal fade myModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close btn_close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    I hereby authorize Aditya Birla Housing Finance Limited to conduct Video KYC and to
                                    collect and store my 'Proof of Identity, 'Proof of Address' & 'Live picture' during
                                    the video KYC process for the purpose of KYC identity verification.{' '}
                                </p>
                                <p>
                                    I am authorizing Aditya Birla Housing Finance Limited to do the offline verification
                                    of Aadhaar for identification purpose.
                                </p>
                                <p>
                                    I am aware my Aadhaar number will not be stored or will be redacted by Aditya Birla
                                    Housing Finance Limited.
                                </p>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="varify_btn"
                                        data-dismiss="modal"
                                        onClick={this.onCompleteKycButtonClick}
                                    >
                                        Agree
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BubbleSpinLoader isLoading={this.state.isLoading}></BubbleSpinLoader>
                <Error
                    isError={this.state.isError}
                    dismissError={this.dismissError}
                    errorMessage={this.state.errorMessage}
                ></Error>
                <form
                    ref={this.formRef}
                    name="URL"
                    method="POST"
                    action={this.state.alfafinSoftUrl}
                    className="form-data"
                >
                    <input type="hidden" value="EKYC" name="ServiceName" />
                    <input type="hidden" name="Request" value={this.state.requestData} />
                </form>
            </div>
        );
    }
}

export default Authenticate;

import EncyptUtils from '../Utils/EncyptUtils';
import axios from 'axios';
import { UrlPath } from '../Config/UrlPaths';

export function getAuthCode(userId, password, sessionSalt, topOrgCOde, verificationCode) {
    var aesKey = EncyptUtils.generandomhexstr(32);
    const encryptedPassword = EncyptUtils.AesEncrypt(aesKey, password);
    var rsaPublicKey = EncyptUtils.rsaPublicKey;
    let encryptedKey = EncyptUtils.RsaEncrypt(rsaPublicKey, aesKey);
    const paramsData = {
        userId: userId,
        password: encryptedPassword,
    };
    let config = {
        url: UrlPath.login,
        method: 'post',
        data: paramsData,
        responseDataMode: 'original',
        headers: { 'X-SLP-AES-KEY': encryptedKey, 'X-SLP-SESSION-SALT': sessionSalt },
    };
    if (verificationCode) {
        config.headers['X-SLP-SYS-CAPTCHA'] = verificationCode;
    }
    if (topOrgCOde) {
        config.headers['X-SLP-TOP-ORG'] = topOrgCOde;
    }
    return axios(config)
        .then((res) => {
            if (res.status === 200) {
                return res.headers;
            }
        })
        .catch((err) => {
            console.log(err);
            throw err;
        });
}

export function getUserData(accessCode, topOrgCOde, sessionSalt, authCode) {
    let config = {
        url: UrlPath.getAfsDataForCallback + accessCode,
        method: 'get',
        responseDataMode: 'original',
        headers: { 'X-SLP-SESSION-SALT': sessionSalt, 'X-SLP-AUTH-CODE': authCode, 'X-SLP-USER-ID': 1 },
    };
    if (topOrgCOde) {
        config.headers['X-SLP-TOP-ORG'] = topOrgCOde;
    }
    return axios(config)
        .then((res) => {
            if (res.status === 200) {
                return res.data;
            }
        })
        .catch((err) => {
            console.log(err);
            throw err;
        });
}

export function getToken(accessCode, topOrgCOde, sessionSalt, authCode, currentLocation) {
    const paramsData = {
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
    };
    let config = {
        url: UrlPath.verifyTokenGetSessionDtls + accessCode,
        method: 'post',
        data: paramsData,
        responseDataMode: 'original',
        headers: { 'X-SLP-SESSION-SALT': sessionSalt, 'X-SLP-AUTH-CODE': authCode, 'X-SLP-USER-ID': 1 },
    };
    if (topOrgCOde) {
        config.headers['X-SLP-TOP-ORG'] = topOrgCOde;
    }
    return axios(config)
        .then((res) => {
            if (res.status === 200) {
                console.log(res);
                return res.data;
            }
        })
        .catch((err) => {
            throw err;
        });
}

import React from 'react';
import PropTypes from 'prop-types';
import UserVideoComponent from '../OpenVidu/UserVideoComponent';

const JoinCallComponent = ({
    myUserName = '',
    publisher,
    ov,
    toggleCamera,
    joinSession,
    handleChangeUserName,
    isLocationRequestGranted,
}) => {
    return (
        <div id="video-container" className="row">
            <div id="join-dialog" className="jumbotron vertical-center">
                <h2 className="text-align-center"> Join the video session </h2>
                <div className="row uservideo-component-wrapper">
                    <UserVideoComponent
                        streamManager={publisher}
                        OV={ov}
                        isPublisher={true}
                        toggleCamera={toggleCamera}
                        isSessionStarted={false}
                        isJoinCallComponent={true}
                    />
                </div>
                <form className="form-group">
                    <p>
                        <label>Name: </label>
                        <input
                            className="form-control"
                            type="text"
                            id="userName"
                            value={myUserName}
                            placeholder="Enter Your Name."
                            onChange={handleChangeUserName}
                            required
                        />
                    </p>
                    <p className="text-center">
                        <input
                            className="btn btn-lg btn-success"
                            type="button"
                            value="JOIN CALL"
                            onClick={joinSession}
                            disabled={!isLocationRequestGranted}
                        />
                    </p>
                </form>
            </div>
        </div>
    );
};

JoinCallComponent.propTypes = {
    myUserName: PropTypes.string,
    publisher: PropTypes.object.isRequired,
    ov: PropTypes.any.isRequired,
    toggleCamera: PropTypes.func.isRequired,
    joinSession: PropTypes.func.isRequired,
    handleChangeUserName: PropTypes.func.isRequired,
    isLocationRequestGranted: PropTypes.bool,
};

export default JoinCallComponent;

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import OpenViduVideoComponent from './OpenViduVideoComponent';
import './userVideo.css';
import { CameraIcon, MicOnIcon, MicOffIcon, CameraOnIcon, CameraOffIcon } from '../../Images/Icons';

const disableColor = 'grey';
const enableColor = 'red';

export default class UserVideoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCameraToggleShow: false,
            isVideoEnabled: true,
            isAudioEnabled: true,
        };
    }

    componentDidMount() {
        this.setCameraToggleButton();
    }

    setCameraToggleButton() {
        this.props.OV.getDevices().then((devices) => {
            let videoDevices = devices.filter((device) => device.kind === 'videoinput');
            if (videoDevices && videoDevices.length > 1) {
                this.setState({
                    isCameraToggleShow: true,
                });
            }
        });
    }

    getNicknameTag() {
        // Gets the nickName of the user
        // if (this.props.isSessionStarted) {
        //     return JSON.parse(this.props.streamManager.stream.connection.data).clientData;
        // }
        return '';
    }

    toggleCamera = () => {
        if (this.props.isPublisher) {
            this.props.toggleCamera();
        }
    };

    camStatusChanged = () => {
        this.props.streamManager.publishVideo(!this.state.isVideoEnabled);
        this.setState({
            isVideoEnabled: !this.state.isVideoEnabled,
        });
    };

    micStatusChanged = () => {
        this.props.streamManager.publishAudio(!this.state.isAudioEnabled);
        this.setState({
            isAudioEnabled: !this.state.isAudioEnabled,
        });
    };

    render() {
        var { isPublisher, isJoinCallComponent } = this.props;
        return (
            <div>
                {this.props.streamManager !== undefined ? (
                    <div className="col user-video-component-container">
                        <div className="nick-name">
                            <div className="user-name">
                                <p>{this.getNicknameTag()}</p>
                            </div>
                        </div>
                        <OpenViduVideoComponent streamManager={this.props.streamManager} />

                        <div className="row bottom-buttons">
                            {this.state.isCameraToggleShow && this.props.isPublisher && !isJoinCallComponent && (
                                <button className="btn margin6 remove-border" onClick={this.toggleCamera}>
                                    <CameraIcon></CameraIcon>
                                </button>
                            )}
                            {isPublisher && (
                                <button
                                    type="button"
                                    className="btn margin6 remove-border"
                                    onClick={this.micStatusChanged}
                                >
                                    {this.state.isAudioEnabled ? (
                                        <MicOffIcon color={enableColor} />
                                    ) : (
                                        <MicOnIcon color={disableColor} />
                                    )}
                                </button>
                            )}
                            {isPublisher && (
                                <button
                                    type="button"
                                    className="btn margin6 remove-border"
                                    onClick={this.camStatusChanged}
                                >
                                    {this.state.isVideoEnabled ? (
                                        <CameraOffIcon color={enableColor} />
                                    ) : (
                                        <CameraOnIcon color={disableColor} />
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

import React from 'react';
import './Navbar.css';
import Logo from '../../Images/abc-logo-3-d@3x.png';
import LogoL from '../../Images/logo-l.png';
import Phone from '../../Images/phone.svg';
import Home from '../../Images/home.svg';

export default function Navbar() {
    return (
        <header>
            <div className="bodywrapper">
                <div className="overlay hidden-xs hidden-lg hidden-md hidden-sm"></div>
                <div className="">
                    <div className="mast_head" style={{ padding: 0 }}>
                        <header className="header-static">
                            <div className="header-top">
                                <div className="div_head">
                                    <div className="col-md-6 hidden-xs topnavhidden"></div>
                                    <div className="col-md-6 hidden-sm topnavlist hidden-xs">
                                        <div className="pull-right">
                                            <nav>
                                                <ul className="nav nav-service navbar-nav nav-pills">
                                                    <li>
                                                        <a href="#">Corporates</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Advisors</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Customer Service</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">Careers</a>
                                                    </li>
                                                    <li className="divider-left">
                                                        <a href="#">About us</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav className="navbar navbar-default navbar-static custom-nav" id="mainNavbar">
                                <div className="navigation_div">
                                    <div className="navbar-left">
                                        <span className="icon-icon-login abcicons cust-log-ico-mob hidden-md hidden-lg"></span>
                                        <button
                                            type="button"
                                            className="navbar-toggle pull-right collapsed menu-icon"
                                            data-toggle="collapse-side"
                                            data-target=".side-collapse"
                                            data-target-2=".side-collapse-container"
                                        >
                                            <span className="sr-only">Toggle navigation</span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                        <div
                                            className="logo"
                                            itemScope
                                            itemProp="organization"
                                            itemType="http://schema.org/Organization"
                                        >
                                            <div className="hidden-xs hidden-sm">
                                                <a itemProp="url" href="#">
                                                    <img src={Logo} />
                                                </a>
                                            </div>
                                            <div className="hidden-md hidden-lg">
                                                <a href="#">
                                                    <img src={LogoL} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="navbar-right hidden-sm hidden-xs">
                                        <div className="navbar-activity">
                                            <ul className="nav navbar-nav center-list">
                                                <li className="right-log">
                                                    <a className="text-uppercase user-log" href="#">
                                                        <span>1800-270-7000</span>
                                                        <span className="hidden-xs hidden-sm">
                                                            <img className="right-img" src={Phone} />
                                                        </span>
                                                    </a>
                                                </li>
                                                <li className="right-log">
                                                    <a className="text-uppercase user-log" href="#">
                                                        <span>home</span>
                                                        <span className="hidden-xs hidden-sm">
                                                            <img className="right-img" src={Home} />
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </header>
                    </div>
                </div>
            </div>
        </header>
    );
}

import React from 'react';
export const CameraIcon = ({ color = '#000000' }) => {
    return (
        <svg
            height="1em"
            viewBox="0 -25 512 512"
            width="1em"
            className="bi bi-camera"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m472 282v-130c0-22.054688-17.945312-40-40-40h-30.359375c-25.695313 0-48.523437-16.34375-56.800781-40.667969l-6.050782-17.777343c-2.761718-8.105469-10.371093-13.554688-18.933593-13.554688h-127.773438c-8.398437 0-15.957031 5.308594-18.808593 13.207031l-6.925782 19.171875c-8.554687 23.699219-31.234375 39.621094-56.429687 39.621094h-29.917969c-22.054688 0-40 17.945312-40 40v230c0 22.054688 17.945312 40 40 40h352c22.054688 0 40-17.945312 40-40 0-11.046875 8.953125-20 20-20s20 8.953125 20 20c0 44.113281-35.886719 80-80 80h-352c-44.113281 0-80-35.886719-80-80v-230c0-44.113281 35.886719-80 80-80h29.917969c8.398437 0 15.957031-5.308594 18.808593-13.207031l6.925782-19.171875c8.554687-23.699219 31.234375-39.621094 56.429687-39.621094h127.773438c25.691406 0 48.519531 16.34375 56.796875 40.664062l6.050781 17.78125c2.761719 8.109376 10.371094 13.554688 18.9375 13.554688h30.359375c44.113281 0 80 35.886719 80 80v130c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20zm-242.816406 3c0-11.046875-8.953125-20-20-20h-51c-22.15625 0-40.183594 17.945312-40.183594 40v50c0 11.046875 8.953125 20 20 20s20-8.953125 20-20v-19.648438c23.265625 31.132813 60.328125 50.648438 101 50.648438 69.476562 0 126-56.523438 126-126s-56.523438-126-126-126c-44.839844 0-86.65625 24.136719-109.132812 62.984375-5.527344 9.5625-2.261719 21.796875 7.300781 27.328125 9.558593 5.53125 21.792969 2.261719 27.324219-7.296875 15.347656-26.535156 43.898437-43.015625 74.507812-43.015625 47.421875 0 86 38.578125 86 86s-38.578125 86-86 86c-30.476562 0-57.996094-16.035156-73.328125-41h23.511719c11.046875 0 20-8.953125 20-20zm0 0" />
        </svg>
    );
};

export const MicOnIcon = ({ color = '#000000' }) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-mic"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
            />
            <path
                fillRule="evenodd"
                d="M10 8V3a2 2 0 1 0-4 0v5a2 2 0 1 0 4 0zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"
            />
        </svg>
    );
};

export const MicOffIcon = ({ color = '#000000' }) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-mic-mute"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M12.734 9.613A4.995 4.995 0 0 0 13 8V7a.5.5 0 0 0-1 0v1c0 .274-.027.54-.08.799l.814.814zm-2.522 1.72A4 4 0 0 1 4 8V7a.5.5 0 0 0-1 0v1a5 5 0 0 0 4.5 4.975V15h-3a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-3v-2.025a4.973 4.973 0 0 0 2.43-.923l-.718-.719zM11 7.88V3a3 3 0 0 0-5.842-.963l.845.845A2 2 0 0 1 10 3v3.879l1 1zM8.738 9.86l.748.748A3 3 0 0 1 5 8V6.121l1 1V8a2 2 0 0 0 2.738 1.86zm4.908 3.494l-12-12 .708-.708 12 12-.708.707z"
            />
        </svg>
    );
};

export const CameraOnIcon = ({ color = '#000000' }) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-camera-video"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175l3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
            />
        </svg>
    );
};

export const CameraOffIcon = ({ color = '#000000' }) => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-camera-video-off"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56l-10-14 .814-.58 10 14-.814.58z"
            />
        </svg>
    );
};

import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ isError = false, errorMessage = '', dismissError }) => {
    return (
        <>
            {isError && (
                <div className="loader-wrapper">
                    <div
                        className="alert alert-danger alert-dismissible fade in"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <strong style={{ fontSize: 18 }}>{errorMessage}</strong>
                        <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={dismissError}>
                            &times;
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

Error.propTypes = {
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    dismissError: PropTypes.func.isRequired,
};

export default Error;
